import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

type FormOptionProps = {
  name: string;
  control: any;
  disabled?: boolean;
  onChange?: any;
  label: string;
};

const FormOption = ({ name, control, disabled, onChange, label }: FormOptionProps) => {
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Checkbox
              sx={{ padding: 0 }}
              disabled={disabled}
              {...field}
              checked={field.value}
              onChange={(e) => {
                if (onChange) onChange();
                field.onChange(e.target.checked);
              }}
            />
          )}
        />
      }
      label={
        <Typography variant="body1" sx={{ maxWidth: '100%' }}>
          {label}
        </Typography>
      }
    />
  );
};

export default FormOption;
